@use './../../../../node_modules/@ionic/core/css/utils.bundle.css';

.nswbc {
    --ion-color-primary: #007dc3;
    --ion-color-primary-rgb: 0, 125, 195;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #006eac;
    --ion-color-primary-tint: #1a8ac9;

    --ion-color-secondary: #7ed3f7;
    --ion-color-secondary-rgb: 126, 211, 247;
    --ion-color-secondary-contrast: #fff;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #6fbad9;
    --ion-color-secondary-tint: #8bd7f8;

    --ion-color-tertiary: #ecfbff;
    --ion-color-tertiary-rgb: 236, 251, 255;
    --ion-color-tertiary-contrast: #000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #d0dde0;
    --ion-color-tertiary-tint: #eefbff;

    --ion-color-danger: #ff6174;
    --ion-color-danger-rgb: 255, 97, 116;
    --ion-color-danger-contrast: #fff;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #e05566;
    --ion-color-danger-tint: #ff7182;

    --ion-font-family: Verdana, Geneva, sans-serif;
}

.nswbc {
    --actassa-icon-font-size: 24px;

    ion-header {
        &::after {
            background-color: var(--ion-color-secondary);
            background-image: none;
            bottom: -2px;
            height: 2px;
        }
    }

    ion-menu-button,
    ion-back-button {
        --color: var(--ion-color-primary);
        --icon-font-size: var(--actassa-icon-font-size);

        &.ios {
            --icon-padding-start: calc(16px + var(--ion-safe-area-left, 0px));
            --icon-padding-stop: 16px;
        }
    }

    ion-title {
        --color: var(--ion-color-dark);
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    ion-item {
        --highlight-color-valid: var(--ion-color-primary);

        &[button] {
            --background: var(--ion-color-tertiary);
        }
    }

    ion-label {
        font-size: 16px;
        line-height: 19px;

        &.label-stacked {
            transform: scale(1);
        }
    }

    ion-text {
        font-size: 14px;
        line-height: 17px;
    }

    ion-button {
        --border-radius: var(--actassa-border-radius) !important; // TODO KB
        --box-shadow: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        outline: none;
        text-transform: capitalize;

        &.button-medium {
            height: 40px;
        }
    }

    ion-icon {
        font-size: var(--actassa-icon-font-size);
    }

    form {
        ion-list {
            --ion-item-background: transparent;
        }

        ion-item {
            --border-color: var(--ion-color-secondary);
            --background: transparent;
        }

        ion-checkbox {
            --border-color: var(--ion-color-secondary);
            --border-color-checked: var(--ion-color-primary);
            --background-checked: var(--ion-color-primary);
            --ion-padding-start: 0;
        }

        ion-radio {
            --color: var(--ion-color-secondary);
            --color-checked: var(--ion-color-primary);
            --ion-padding-start: 0;
        }
    }

    actassa-list-time {
        --actassa-list-time-shift-icon: 'assets/nswbc/icons/shift.svg';
    }
}
